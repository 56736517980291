/* header for work item */
.workItemHeader {
  @apply relative flex flex-col sm:flex-row gap-x-4
    py-1 bg-background bg-opacity-0 z-10 select-none
    transition-[border-color,background-color] duration-300 ease-[ease] pointer-events-auto
    border-b border-foreground border-opacity-0 px-2 wide:px-4;
}

[data-expanded="1"] .workItemHeader {
  @apply border-opacity-50 bg-opacity-100;
}

[data-peeked="1"] .workItemHeader {
  @apply border-opacity-25 bg-opacity-50;
}

/* entire content of expandable zone */
.workItemContent {
  @apply flex flex-col sm:flex-row items-start text-sm mt-4 pb-8 gap-x-4 overflow-visible -translate-y-2
    transition-transform duration-1000 ease-[ease];
}

@media (prefers-reduced-motion) {
  .workItemContent { @apply transition-none }
}

[data-expanded="1"] .workItemContent {
  @apply translate-y-0
}

.workItemContent p a {
  @apply underline;
}
[data-reduced-motion='n'] .workItemContent p a {
  @apply transition-opacity duration-300 ease-[ease];
}

.workItemContent p a:hover {
  @apply opacity-50;
}

.workItemContent p:first-of-type {
  @apply indent-2 wide:indent-4;
}

/* project preview image container */
.imageContainer {
  @apply w-full sm:w-1/2;
  display: block;
  position: relative;
  object-fit: contain;
  aspect-ratio: var(--gr)/1;
  overflow: visible;
  background-color: rgb(var(--foreground) / 50%);
  clip-path: url("#outlineClip");
}

/* this creates a stroke */
.imageContainer::after {
  content: '';
  position: absolute;
  z-index: 2;
  left: 1px;
  top: 1px;
  width: calc(100% - 2px);
  height: calc(100% - 2px);
  background-color: rgb(var(--background-inner));
  clip-path: url("#outlineClip");
  transition: background 1.5s ease;
}

[data-expanded="1"]:hover .imageContainer::after {
  background-color: white;
}

[data-theme='d'] [data-expanded="1"]:hover .imageContainer::after {
  background-color: black;
}

@media (prefers-color-scheme: dark) {
  [data-theme='u'] [data-expanded="1"]:hover .imageContainer::after {
    background-color: black;
  }
}

@media (prefers-color-scheme: light) {
  [data-theme='u'] [data-expanded="1"]:hover .imageContainer::after {
    background-color: white;
  }
}

@media (hover: none) {
  [data-expanded="1"] .imageContainer::after {
    background-color: white;
  }
  
  [data-theme='d'] [data-expanded="1"] .imageContainer::after {
    background-color: black;
  }
}

.imageContainer > div {
  @apply absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2
    mix-blend-darken w-[calc(100%-1px)] h-[calc(100%-1px)] z-10;
  clip-path: url("#outlineClip");
}

[data-theme='d'] .imageContainer > div {
  @apply mix-blend-lighten;
}

@media (prefers-color-scheme: dark) {
  [data-theme='u'] .imageContainer > div {
    @apply mix-blend-lighten;
  }
}

/* actual project image */
.previewImage {
  @apply saturate-0 transition-[filter] duration-[1.5s] delay-0 ease-[ease];
}

[data-expanded="1"]:hover .previewImage {
  @apply saturate-[0.8];
}

@media (hover: none) {
  [data-expanded="1"] .previewImage {
    @apply saturate-[0.8];
  }
}
